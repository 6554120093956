import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Box,
  Chip,
  CircularProgress,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { AccountCircle, Search } from "@mui/icons-material";

function App() {
  const [isSearchShown, setIsSearchShown] = React.useState<boolean>(false);
  const [isNoticeShown, setIsNoticeShown] = React.useState<boolean>(false);

  function doSearch() {
    setIsSearchShown(true);
    setTimeout(() => {
      setIsNoticeShown(true);
    }, 5000);
  }

  return (
    <div className="App">
      <Paper
        elevation={3}
        sx={{
          width: 600,
          height: 400,
          position: "fixed",
          top: "calc(50% - 200px)",
          left: "calc(50% - 300px)",
          textAlign: "center"
        }}
      >
        <Typography variant="h1" className="MainText">
          Saucy Presidents
        </Typography>
        <Typography variant="body1" sx={{ fontSize: 12 }}>
          All of your favorite naughty presidents, in one place.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "40px"
          }}
        >
          <Search
            sx={{
              color: "action.active",
              mr: 1,
              mt: 2.5,
              height: "36px",
              width: "36px"
            }}
          />
          <TextField
            id="input-with-sx"
            label="Search for a saucy president..."
            variant="standard"
            sx={{ width: 300 }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                doSearch();
              }
            }}
            disabled={isSearchShown}
          />
        </Box>

        <Typography variant="body1" sx={{ fontSize: 14, marginTop: 1.5 }}>
          Popular right now:
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          sx={{ justifyContent: "center", marginTop: 1 }}
        >
          <Chip
            label="Johnson's Johnson"
            variant="outlined"
            onClick={() => {
              doSearch();
            }}
            disabled={isSearchShown}
          />
          <Chip
            label="Taft's Shaft"
            variant="outlined"
            onClick={() => {
              doSearch();
            }}
            disabled={isSearchShown}
          />
          <Chip
            label="George's Bush"
            variant="outlined"
            onClick={() => {
              doSearch();
            }}
            disabled={isSearchShown}
          />
        </Stack>
      </Paper>
      {isSearchShown ? (
        <CircularProgress
          color={"inherit"}
          sx={{
            color: "white",
            position: "fixed",
            top: "calc(50% + 220px)"
          }}
        />
      ) : (
        <></>
      )}
      {isNoticeShown ? <div className="noticebg"></div> : <></>}
    </div>
  );
}

export default App;
